<template>
	<div id="top1"></div>
	<el-container>
		<el-header  class="top-nav">
			<el-row  align="middle">
				<el-col :span="2" style="text-align:center">
					<el-image :src="logo" style="width:100px;height:50px"></el-image>
				</el-col>
				<el-col :offset="20" :span="2">
					<el-link href="#top1">首页</el-link>
					<el-link href="#about_me" style="margin-left:20px">关于我们</el-link>
				</el-col>
			</el-row>
		</el-header>
		<!-- <el-divider></el-divider> -->

		<el-container>

			<el-main>

                <el-row  align="middle">
                    <el-col :span="24">
                    <el-image :src="img1"  fit="fill" style="width:100%" width="100%"></el-image>
                    </el-col>
                </el-row>
				<el-row>
					<el-col :span="24" class="content"><h2>值得信赖的移动支付平台</h2></el-col>
				</el-row>
				<el-row>
					<el-col :span="8" class="content" style="">
						<el-image :src="stabilize_icon"></el-image>
					</el-col>
					<el-col :span="8" class="content" style="">
						<el-image :src="efficient_icon"></el-image>
					</el-col>	
					<el-col :span="8" class="content" style="">
						<el-image :src="authority_icon"></el-image>
					</el-col>									
				</el-row>
				<el-row>
					<el-col :span="8" class="content"><h4>稳定</h4></el-col>
					<el-col :span="8" class="content"><h4>高效</h4></el-col>
					<el-col :span="8" class="content"><h4>权威</h4></el-col>

				</el-row>
				<div class="box2">
				<el-row>
					<el-col class="content"><h2 style="color: white">我们支持全渠道移动支付体系</h2></el-col>
				</el-row>
				<el-row style="margin:20px">
					<el-col :span="12" class="content" ><el-image :src="wechat"></el-image></el-col>
					<!-- <el-col :span="4" class="content" ><el-image :src="alipay_icon"></el-image></el-col> -->
					<!-- <el-col :span="4" class="content" ><el-image :src="qqpay_icon"></el-image></el-col> -->
					<el-col :span="12" class="content" ><el-image :src="unionpay_icon"></el-image></el-col>
					<!-- <el-col :span="4" class="content" ><el-image :src="baidupay_icon"></el-image></el-col> -->
					<!-- <el-col :span="4" class="content" ><el-image :src="hepay_icon"></el-image></el-col> -->

				</el-row>
				</div>
				<el-row align="middle">
					<el-col class="content"><h2>我们提供多种收银方案</h2></el-col>
				</el-row>
				<div class="box3 content">
				<el-row class="row">
					<el-col :span="12" class="box3 col">
						<el-image :src="scan_icon"></el-image>
						<h4>扫码枪</h4>
						<p>支持扫条形码和二维码</p>
					</el-col>
					<el-col :span="12" class="box3 col">
						<el-image :src="scanbox_icon"></el-image>
						<h4>扫码盒</h4>
						<p>即插即用 识别灵敏</p>						
					</el-col>
				</el-row>

				<el-row class="row">
					<el-col :span="12" class="box3 col">
						<el-image :src="printer_icon"></el-image>
						<h4>云打印机</h4>
						<p>二维码主扫收银最佳搭档</p>						
					</el-col>
					<el-col :span="12" class="box3 col">
						<el-image :src="voice_icon"></el-image>
						<h4>语音播报</h4>
						<p>收银提醒 智能小秘书</p>						
					</el-col>
				</el-row>

				<el-row class="row">
					<el-col :span="12" class="box3 col">
						<el-image :src="app_icon"></el-image>
						<h4>APP收银</h4>
						<p>随时随地 收银查账</p>						
					</el-col>
					<el-col :span="12" class="box3 col">
						<el-image :src="qrcode_icon"></el-image>
						<h4>聚合码收银</h4>
						<p>多场景 多行业 快速接入</p>						
					</el-col>
				</el-row>

				<el-row class="row" style="border-bottom:1px solid #ccc;margin-bottom:20px">
					<el-col :span="12" class="box3 col">
						<el-image :src="sale_icon"></el-image>
						<h4>单品优惠</h4>
						<p>官方活动 自动核销</p>						
					</el-col>
					<el-col :span="12" class="box3 col">
						<el-image :src="api_icon"></el-image>
						<h4>API接口</h4>
						<p>高效开发 灵活协作 安全部署</p>						
					</el-col>
				</el-row>
				</div>

				<div class="box4" >
					<!-- <el-row>
						<el-col class="content"><h2>我们的合作伙伴</h2></el-col>
					</el-row>
					<el-row style="background-color: white;margin:20px">
						<el-col>
							<el-image :src="partner_logo" fit="fill" style="width:100%" width="100%"></el-image>
						</el-col>
					</el-row> -->
					<el-row>
						<el-col class="content"><h2>关于我们</h2></el-col>
					</el-row>
					<el-row id="about_me" style="margin:20px">
						<el-col>
							<p>途付通是一家微信授权服务商，帮助商家在线下门店，小程序，公众号，移动应用app，网页等各个交易场景下使用微信支付进行收款，满足商家不同场景下的支付诉求，让商家轻松接入微信支付。</p>
						</el-col>
					</el-row>
				</div>
			</el-main>
			<el-divider></el-divider>
			<el-footer>
				<el-row>
					<el-col :offset="10" :span="12">
						<p>
							Copyright ©2013－2021 拼途科技（tufutong.com）版权所有
						</p>
					</el-col>
				</el-row>
				<el-row>
					<el-col :offset="10" :span="12">
						<el-link href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank" type="primary" style="font-size:12px; font-weight:400;"	>京ICP备2021041324号-1号</el-link>  
						<el-image
							class="gongan"
							style="width: 12px; height: 12px;display:inline"
							:src="gongan_icon"
							:fit="fit"
						></el-image>
						<el-link href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802038576" target="_blank" type="primary" style="font-size:12px; font-weight:400;">京公网安备 11010802038576号</el-link>	
					</el-col>
					<el-col :span="4">
						
					</el-col>
				</el-row>
				<div style="height: 20px"></div>
			</el-footer>
		</el-container>
	</el-container>
</template>
<script>
import logo from '../assets/logo.png'
import stabilize_icon from "@/assets/stabilize_icon.png"
import efficient_icon from "@/assets/efficient_icon.png"
import authority_icon from "@/assets/authority_icon.png"
import wechat from '@/assets/wechat_icon.png'
import alipay_icon from "@/assets/alipay_icon.png"
import qqpay_icon from "@/assets/qqpay_icon.png"
import unionpay_icon from "@/assets/unionpay_icon.png"
import baidupay_icon from "@/assets/baidupay_icon.png"
import hepay_icon from "@/assets/hepay_icon.png"
import scan_icon from "@/assets/scan_icon.png"
import scanbox_icon from "@/assets/scanbox_icon.png"
import printer_icon from "@/assets/printer_icon.png"
import voice_icon from "@/assets/voice_icon.png"
import app_icon from "@/assets/app_icon.png"
import qrcode_icon from "@/assets/qrcode_icon.png"
import sale_icon from "@/assets/sale_icon.png"
import api_icon from "@/assets/api_icon.png"
import partner_logo from "@/assets/partner/partner_logo.jpg"
import img1 from "@/assets/img1.jpg"
import gongan_icon from "@/assets/gongan.png"


export default {
	data() {
		return {
			logo: logo,
			stabilize_icon,
			efficient_icon,
			authority_icon,
			wechat,
			qqpay_icon,
			alipay_icon,
			unionpay_icon,
			baidupay_icon,
			hepay_icon,
			scan_icon,
			scanbox_icon,
			printer_icon,
			voice_icon,
			app_icon,
			qrcode_icon,
			sale_icon,
			api_icon,
			partner_logo,
			img1,
			gongan_icon,
			activeIndex: '2',
		}
	},
	methods: {
		handleSelect() {
			console.log('haha')
		},
	},
}
</script>
<style lang="css" scoped>
.top-nav {
	background-color: white;
}
.content {
	text-align: center;
}
.box2 {
	background-color: #445AAE;
	height: 300px;
}
.box3 h4 {
	font-size: 1.2rem;
	margin: 0px;
}
.box3 p {
	font-size: .9rem;
    color: #999;
	margin: 0px;
}
.row {
	margin: 0px 20px;
	border-left: 1px solid #ccc;
	/* border-bottom: 1px solid #ccc; */
}
.col {
	border-right: 1px solid #ccc;
	border-top: 1px solid #ccc;
	height: 150px;
	padding: 20px;
}

.box4 {
	background-color: #F9F9F9;
	margin-bottom: 50px;

}
.box4 p {
	text-indent: 2rem;
    line-height: 1.6rem;
    font-weight: 500;
    text-align: justify;
}

::v-deep(.el-menu--horizontal) {
	display: flex;
	flex-wrap: nowrap;
	border-bottom: none;
	border-right: none;
}
::v-deep(.el-main){
    --el-main-padding: 0px;
    display: block;
    flex: 1;
    flex-basis: auto;
    overflow: auto;
    box-sizing: border-box;
    padding: var(--el-main-padding);
	position: relative;
	top: 60px;
	width: 100%;

}
::v-deep(.el-divider--horizontal) {
    display: block;
    height: 1px;
    width: 100%;
    margin: 40px 10px 0;
}
::v-deep(.el-header) {
	--el-header-padding: 0 20px;
    --el-header-height: 60px;
    padding: var(--el-header-padding);
    box-sizing: border-box;
    flex-shrink: 0;
    /* height: var(--el-header-height); */
	height: 60px;
    position: fixed;
	width: 100%;
	z-index: 20;

}
::v-deep(.el-footer) {
    --el-footer-padding: 0px 20px;
    --el-footer-height: 60px;
    padding: var(--el-footer-padding);
    box-sizing: border-box;
    flex-shrink: 0;
    height: var(--el-footer-height);
	font-size: 12px;
    font-weight: 400;
    color: #a0a0a0;
    line-height: 18px;
}
::v-deep(.gongan .el-image__inner) {
	width: 12px;
    height: 12px;
    margin-left: 10px;
    margin-top: 3px;
}
</style>